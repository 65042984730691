<template>
  <div class="content-bd" :class="ClassLayout">
    <div class="content-left" :class="{ 'fadeInLeft animated': $store.state.animationClass }">
      <section class="practice">
        <header class="section-hd">
          <span class="section-title-l"></span>
          <h2 class="section-title">工作经验</h2>
          <span class="section-title-r"></span>
        </header>
        <div class="section-bd">

          <!--工作经历:外包-->
          <div class="item">
            <header class="item-hd">
              <div class="item-hd-box">
                <div class="item-hd-box-img" @click="showBox(3)">
                  <img :src="BoxList[3].imgUrl" alt="">
                </div>
                <div>
                  <h3 class="item-name">张旅工作室（项目外包）</h3>
                  <span class="item-time">2022-01-08 至 2022-12-23</span>
                </div>
              </div>
              <a class="btn item-more" href="" title="点击查看->张家界百度百科">WEB前端开发</a>
            </header>
            <ol :class="BoxList[3].class == true ? 'NOBox' : ''" class="item-project">
              <li class="project-1">
                <h3 class="project-name">前端开发</h3>
                <ol>
                  <li class="project-2">
                    <div class="item-bd">
                      <h3 class="project-name-2">小程序开发</h3>
                      <div class="div-item">
                        <h3 class="project-des">【工作职责】</h3>
                        <ul class="section-content">
                          <li>对接UI设计师的原型图进行开发，完成页面的1:1还原</li>
                          <li>对uniapp在不同环境下的开发如H5、微信小程序、微信公众号</li>
                          <li>主要负责特产页面设计和开发</li>
                          <li>特产购物车开发，下单系统购买微信支付模块</li>
                          <li>解决开发过程中产生的兼容性问题</li>
                        </ul>
                      </div>
                    </div>

                  </li>

                </ol>
              </li>
            </ol>
          </div>
          <div class="item">
            <header class="item-hd">
              <div class="item-hd-box">
                <div class="item-hd-box-img" @click="showBox(4)">
                  <img :src="BoxList[4].imgUrl" alt="">
                </div>
                <div>
                  <h3 class="item-name">进芯电子</h3>
                  <span class="item-time">2022-09-04 至 至今</span>
                </div>
              </div>
              <a class="btn item-more" href="" title="点击查看->进芯电子">前端开发</a>
            </header>
            <ol :class="BoxList[4].class == true ? 'NOBox' : ''" class="item-project">
              <li class="project-1">
                <h3 class="project-name">前端开发</h3>
                <ol>
                  <li class="project-2">
                    <div class="item-bd">
                      <h3 class="project-name-2">页面开发</h3>
                      <div class="div-item">
                        <h3 class="project-des">【工作职责】</h3>
                        <ul class="section-content">
                          <li>完成上级下发任务</li>

                        </ul>
                      </div>
                    </div>

                  </li>

                </ol>
              </li>
            </ol>
          </div>

        </div>

      </section>
      <section class="practice">
        <header class="section-hd">
          <span class="section-title-l"></span>
          <h2 class="section-title">项目经验</h2>
          <span class="section-title-r"></span>
        </header>
        <div class="section-bd">

          <!--经历:AI开放社区-->
          <div class="item">
            <header class="item-hd">

              <div class="item-hd-box">
                <div class="item-hd-box-img" @click="showBox(0)">
                  <img :src="BoxList[0].imgUrl" alt="">
                </div>
                <div>
                  <h3 class="item-name">AI开放社区(已参加比赛并获奖)</h3>
                  <span class="item-time">2022.01 到 至今 </span>
                </div>
              </div>
              <a class="btn item-more" href="http://andysama.work" target="_blank"
                title="点击查看->AI开放社区首页">主要负责人(个人独立开发)</a>
            </header>
            <ol :class="BoxList[0].class == true ? 'NOBox' : ''" class="item-project">
              <li class="project-1">
                <h3 class="project-name">
                  <a href="https://andysama.work" target="_blank" title="点击进入->AI开放社区首页">项目地址 https://andysama.work</a>
                </h3>
                <ol>
                  <li class="project-2">
                    <div class="item-bd">
                      <h3 class="project-name-2">基本内容</h3>
                      <div class="div-item">
                        <h3 class="project-des">【项目简介】</h3>
                        <p class="item-project-des">
                          AI开放社区通过提供一个集成AI产品的平台，帮助用户快速找到最适合他们需求的AI解决方案，
                          并通过社区的指引正确地使用这些产品。该项目主要关注两个方向的AI应用：<br>
                          一是让AI服务于用户的产品，例如ChatGPT、AI虚拟数字人和AI助手；<br>
                          二是用户与AI共同创造的领域，如AI绘画、AI音乐创作、AI生成PPT和文档等。<br>
                          AI开放社区的核心功能包括以下几个方面：
                          AI产品搜索和推荐：用户可以通过平台快速搜索和筛选出符合他们需求的AI产品。平台利用智能推荐算法，根据用户的需求和偏好，推荐最合适的AI解决方案。<br>
                          社区指引和支持：用户可以在社区中获取有关AI产品的使用指南、技术支持和最佳实践分享。社区成员可以相互交流经验、解决问题，并分享创新的AI应用想法。<br>
                          知识技术交流分享：AI开放社区提供一个平台，让用户分享和交流有关AI技术、创新和应用的知识。用户可以发布博客文章、教程、案例研究和行业趋势分析等内容，促进AI领域的知识共享和合作<br>
                        </p>
                      </div>
                      <div class="div-item">
                        <h3 class="project-des">【主要技术栈】</h3>
                        <ul class="section-content">
                          <li>使用Vue.js+ElementUI绘制前端基础页面，结合vue中MarkDown插件,通过身份校验动态拼接路由</li>
                          <li>Spring Boot 和 Spring Cloud 是构建我们服务器端服务的主要技术，使用jwt进行身份校验，结合阿里云javaSDK继承语音输入，人脸识别，虚拟数字人等技术。
                          </li>
                          <li>对常用数据进行缓存处理（Redis），并对[撮合引擎]系统进行了架构设计
                          </li>
                          <li>(Nacos)动态配置服务允许用户在所有环境中以集中和动态的方式管理所有服务的配置。消除了在更新配置时重新部署应用程序，这使配置的更改更加高效和灵活
                          </li>
                          <li>使用(Elasticsearch)将大量数据进行高并发环境处理,可以快速地查找他们需要的信息，提高了我们的网站的响应速度。
                          </li>
                          <li>(MinIO) 是我们项目中用于存储和管理文件的系统。无论是用户上传的图片，还是我们提供下载的文件，都可以由 MinIO
                            安全、高效地存储和分发。它的分片上传功能对于处理大文件尤其有用。
                          </li>
                          <li>(Kibana) 是 （Elasticsearch） 的可视化工具，让我们的开发者可以更方便地管理和调整数据。
                          </li>
                          <li>(XXL-JOB) 是一个任务调度框架，我们用它来管理一些定期或定时执行的任务。例如，每天自动刷新用户经验值，审核用户上传的文件等。这样可以大大节省我们的人力资源。
                          </li>
                        </ul>
                      </div>
                      <div class="div-item">
                        <h3 class="project-des">【项目部署】</h3>
                        <ul class="section-content">
                          <li>主要采用腾讯云轻量级服务器，进行项目部署</li>
                          <li>前端通过Vue.js打包后使用Nginx进行端口转发</li>
                          <li>后端采用Docker打包部署
                          </li>
                          <li>
                            使用Docker搭建SpringCloud基础环境如Nacs、MinIO、Elasticsearch、kibana、Redis、xxl-Job
                          </li>
                        </ul>
                      </div>
                    </div>

                  </li>

                </ol>
              </li>
              <li class="project-1">
                <h3 class="project-name">
                  <a href="http://andysama.work:8555/" target="_blank" title="点击进入->数字货币钱包">后续升级课程内容</a>
                </h3>
                <ol>
                  <li class="project-2">
                    <div class="item-bd">
                      <h3 class="project-name-2">后续升级</h3>
                      <div class="div-item">
                        <h3 class="project-des">【升级内容】</h3>
                        <p class="item-project-des">
                          后续使用微服务技术进行升级如：Elasticsearch提供检索高并发环境，MinIo，分布式管理，xxl-Job提供发布事物线上管理，Nacos线上更改配置文件方便管理环境</p>
                      </div>
                      <!-- <div class="div-item">
                            <h3 class="project-des">【工作职责】</h3>
                            <ul class="section-content">
                              <li>负责项目框架搭建以及链上交互开发</li>
                              <li>通过公链提供API以及JSON RPC文档实现数字货币转账、充提币、钱包创建等逻辑</li>
                            </ul>
                          </div> -->
                    </div>
                  </li>
                </ol>
              </li>
            </ol>
          </div>

          <!--经历:单招-->
          <div class="item">
            <header class="item-hd">

              <div class="item-hd-box">
                <div class="item-hd-box-img" @click="showBox(1)">
                  <img :src="BoxList[1].imgUrl" alt="">
                </div>
                <div>
                  <h3 class="item-name">开发单招系统</h3>
                  <span class="item-time">2022.07 至 2022.12 </span>
                </div>
              </div>
              <a class="btn item-more" href="https://www.zjjhy.edu.cn/" title="点击查看->张家界航空工业职业技术学院官网">在校</a>
            </header>
            <ol :class="BoxList[1].class == true ? 'NOBox' : ''" class="item-project">
              <li class="project-1">
                <h3 class="project-name">单招系统</h3>
                <ol>
                  <li class="project-2">
                    <div class="item-bd">
                      <h3 class="project-name-2">单招系统</h3>
                      <div class="div-item">
                        <h3 class="project-des">【项目简介】</h3>
                        <p class="item-project-des">
                          为母校开发单招系统完成系统内包含查询准考证、成绩、录取通知、下载准考证、
                          首次接触java高并发场景让3000+考生稳定访问并下载准考证
                        </p>
                      </div>
                      <div class="div-item">
                        <h3 class="project-des">【工作职责/技术栈分析】</h3>
                        <ul class="section-content">
                          <li>负责项目框架搭建以及页面设计</li>
                          <li>主要采用Vue.js开发前端页面，
                            使用诸多Vue插件如、
                            将页面元素绘制成图片并提供下载（准考证下载）、
                            将表格数据生成为Excel表格（考生信息）
                          </li>
                          <li>后端接口主要采用SpringBoot进行开发，主要技术点为上传（Excel）并使用java读取其内容保存到数据库、
                            生成准考证内容并提供下载、
                            使用（Redis）提供高并发环境性能调优让3000+考生稳定访问</li>
                          <li>上线搭建网站后进行数据维护</li>
                        </ul>
                      </div>
                      <div class="div-item">
                        <h3 class="project-des">【收获】</h3>
                        <ul class="section-content">
                          <li>锻炼了自己的抗压能力、和甲方对接的语言沟通</li>
                          <li>出色的完成了学校下发的所有要求</li>
                          <li>锻炼上线后维护服务器以及突发情况的处理的能力</li>
                        </ul>
                      </div>
                    </div>

                  </li>

                </ol>
              </li>
            </ol>
          </div>

          <!--经历:张家界旅游-->
          <div class="item">
            <header class="item-hd">

              <div class="item-hd-box">
                <div class="item-hd-box-img" @click="showBox(2)">
                  <img :src="BoxList[2].imgUrl" alt="">
                </div>
                <div>
                  <h3 class="item-name">张家界旅游小程序开发</h3>
                  <span class="item-time">2023-01-08 至 2023-02-23</span>
                </div>
              </div>
              <a class="btn item-more"
                href="https://baike.baidu.com/item/%E5%BC%A0%E5%AE%B6%E7%95%8C%E5%B8%82/2515291?fromtitle=%E5%A4%A7%E5%BA%B8%E5%B8%82&fromid=742170"
                title="点击查看->张家界百度百科">参与特产前端开发</a>
            </header>
            <ol :class="BoxList[2].class == true ? 'NOBox' : ''" class="item-project">
              <li class="project-1">
                <h3 class="project-name">
                  <a href="http://ly.andysama.work" target="_blank" title="点击进入-张家界旅游首页">项目地址 http://ly.andysama.work</a>
                </h3>
                <ol>
                  <li class="project-2">
                    <div class="item-bd">
                      <h3 class="project-name-2">小程序开发</h3>
                      <div class="div-item">
                        <h3 class="project-des">【项目简介】</h3>
                        <p class="item-project-des">
                          此项目为张家界旅游区项目开发主要功能为售卖景区门票、定购酒店、售卖特产、规划出行路线
                        </p>
                      </div>
                      <div class="div-item">
                        <h3 class="project-des">【工作职责】</h3>
                        <ul class="section-content">
                          <li>采用Uniapp+UView框架使用HBuilder配合微信开发者工具进行开发</li>
                          <li>主要负责特产页面设计和开发</li>
                          <li>特产购物车开发，下单系统购买微信支付模块</li>
                        </ul>
                      </div>
                      <div class="div-item">
                        <h3 class="project-des">【收获】</h3>
                        <ul class="section-content">
                          <li>锻炼了自己对于小程序和Uniapp的理解</li>
                          <li>养成了团队合作的开发流程和写代码的规范组件的合法拆分</li>
                          <li>让我认识到一个产品打造的全流程所需要用到的技术和重要环节，以及它们的重要性。产品原型设计、UI交互设计、前端技术、后端技术、测试，缺一不可。</li>
                        </ul>
                      </div>
                    </div>

                  </li>

                </ol>
              </li>
            </ol>
          </div>

          <!--经历:在校-->
          <!-- <div class="item">
            <header class="item-hd">
              <h3 class="item-name">豆芽科技工作室</h3>
              <span class="item-time">2015.08 ~ 2017.08</span>
              <a class="btn item-more" href="http://www.ztbu.edu.cn/" title="河南理工大学万方科技学院官网">在校</a>
            </header>
            <div class="item-bd">
              <p class="item-des">在校期间创建工作室，担任技术总监主导和带领朋友创业，工作室曾服务于：<span style="font-weight: bold">高校、企业及个人</span>，部分项目如下：
              </p>
              <ul class="section-content">
                <li><a href="http://www.ztbu.edu.cn/tw" title="点击进入->校团委官网">校团委官网</a> 校方组织比赛，胜出团队有报酬开发并维护团委官网</li>
                <li><a href="https://www.beious.com/" title="点击进入->贝欧名邸官网">贝欧名邸家具</a> 湖南客户公司商城系统</li>
                <li><a href="https://android.myapp.com/myapp/detail.htm?apkName=com.fendoumao.waimai"
                       title="点击查看->奋斗猫APP">奋斗猫APP</a>
                  西安客户公司APP外卖点餐系统
                </li>
                <li>
                  <a href="https://baike.baidu.com/item/%E5%A5%8B%E6%96%97%E7%8C%AB" title="点击查看->奋斗猫百度百科">奋斗猫全网优化</a>
                  奋斗猫全网优化项目包含搜索引擎优化（SEO）
                </li>
              </ul>
            </div>
          </div> -->
        </div>

      </section>
    </div>
    <div class="content-right" :class="{ 'fadeInRight animated': $store.state.animationClass }">
      <section class="skill">
        <header class="section-hd">
          <span class="section-title-l"></span>
          <h2 class="section-title">技能</h2>
          <span class="section-title-r"></span>
        </header>
        <div class="section-bd">

          <!--技能:WEB后端开发-->
          <div class="item">
            <header class="item-hd">
              <span class="item-time">WEB后端开发</span>
              <a class="btn item-more">熟练掌握</a>
            </header>
            <div class="item-bd">
              <ul class="section-content">
                <li>熟练使用Spring Boot、SpringMVC等主流框架，熟悉Restful 风格API设计</li>
                <li>熟悉API开发基本流程，熟悉Restful 风格API设计</li>
                <li>熟练使用消息中间件Rabbit MQ、kafka等开源中间件，有Rabbit MQ实际项目开发使用经验</li>
                <li>了解Spring cloud，正学习Spring cloud在企业大型应用中的应用，以及大型应用的微服务化及服务治理知识学习</li>
                <li>熟练使用非关系型数据库Redis、MongoDB等，有Redis实际项目开发使用经验</li>
                <!-- <li>有金融交易、区块链及数字货币行业独立工作经验</li> -->
              </ul>
            </div>
          </div>

          <!--服务器|架构-->
          <div class="item">
            <header class="item-hd">
              <span class="item-time">服务器|架构</span>
              <a class="btn item-more">熟练掌握</a>
            </header>
            <div class="item-bd">
              <ul class="section-content">
                <li>熟练掌握Nginx、Tomcat、Apache服务器使用</li>
                <li>熟练掌握常用软件（MySQL、Redis、Rabbit MQ、Nacos、xxl-job、Elasticsearch等）在服务器的搭建与使用</li>
                <li>熟练使用Centos(Linux)云服务器并掌握线上环境和应用的部署</li>
                <li>了解Docker使用及Docker应用部署</li>
                <li>熟悉微服务、高并发、高可用集群服务端架构</li>
                <li>熟练掌握Git、Github、gradle、maven等工具使用</li>
              </ul>
            </div>
          </div>

          <!--WEB前端开发-->
          <div class="item">
            <header class="item-hd">
              <span class="item-time">WEB前端开发</span>
              <a class="btn item-more">掌握</a>
            </header>
            <div class="item-bd">
              <ul class="section-content">
                <li>掌握前端流行框架Vue全家桶(Vue-Router,Vuex)</li>
                <li>小程序uniapp+uviewui开发</li>
                <li>熟练使用手机端vantui库</li>
                <li>熟悉前端技术HTML、VUE、JS、CSS有实际项目中的前端开发、维护经验</li>
                <li>掌握网页重构所需的CSS、SCSS</li>
                <li>熟知JS基本语法与知识，熟悉JS语法</li>
              </ul>
            </div>
          </div>

          <!--React-->
          <div class="item">
            <header class="item-hd">
              <span class="item-time">数据库</span>
              <a class="btn item-more">熟练掌握</a>
            </header>
            <div class="item-bd">
              <ul class="section-content">
                <li>熟悉关系型数据库技术，熟练使用关系型数据库Mysql</li>
                <li>熟悉非关系型数据库技术，熟练使用非关系型数据库Nosql：Redis</li>
                <li>了解 MyBatis、JDBC等数据库操作框架使用</li>
              </ul>
            </div>
          </div>


          <!-- //区块链
          <div class="item">
            <header class="item-hd">
              <span class="item-time">区块链</span>
              <a class="btn item-more">熟悉</a>
            </header>
            <div class="item-bd">
              <ul class="section-content">
                <li>熟练掌握以太坊链上操作</li>
                <li>熟悉以太坊DAPP开发；了解EOS公链DAPP开发</li>
                <li>熟练掌握数字钱包、加密货币、交易所开发流程</li>
                <li>两年数字货币交易所系统开发经验，有区块链智能合约、链上交互开发经验</li>
              </ul>
            </div>
          </div> -->

          <!-- //Golang
          <div class="item">
            <header class="item-hd">
              <span class="item-time">Golang</span>
              <a class="btn item-more">熟悉</a>
            </header>
            <div class="item-bd">
              <ul class="section-content">
                <li>熟悉Golang基本语法与标准库</li>
                <li>用Golang开发过小型项目服务</li>
              </ul>
            </div>
          </div> -->

          <!--独立开发-->
          <div class="item">
            <header class="item-hd">
              <span class="item-time">独立开发</span>
            </header>
            <div class="item-bd">
              <ul class="section-content">
                <li>有独自主导并负责的项目开发经验，在项目中可进行独立开发</li>
                <li>有良好的编码习惯与编程思想，学习能力强，可快速学习新技术，对新技术、好技术热爱</li>
                <li>工作稳定性高，对公司负责，有一定的项目管理经验，有创业经历</li>
              </ul>
            </div>
          </div>

          <!-- //其它
          <div class="item">
            <header class="item-hd">
              <span class="item-time">其它</span>
            </header>
            <div class="item-bd">
              <ul class="section-content">
                <li>掌握Java、PHP、Golang、C、Git、Docker、硬件开发</li>
                <li>本科毕设：智能家电物联网控制系统</li>
                <li>资深Mac & JetBrains用户</li>
              </ul>
            </div>
          </div> -->
        </div>
      </section>

      <!--开源项目-->
      <section class="project">
        <header class="section-hd">
          <span class="section-title-l"></span>
          <h2 class="section-title">开源项目</h2>
          <span class="section-title-r"></span>
        </header>
        <div class="section-bd">
          以下是我的开源项目与学习总结，细节请点击链接查看文档（其他项目开发中，尚未开源，敬请期待）

          <!--项目:博客-->
          <div class="item">
            <header class="item-hd">博客/Gitee</header>
            <div class="item-bd">
              <ul>
                <li>
                  <a href="https://blog.csdn.net/qq_64929177?type=blog" target="_blank">CSDN博客</a>
                </li>
                <li>
                  <a href="https://gitee.com/chengcurry30" target="_blank">Gitee</a>
                </li>
                <li>
                  <a href="http://andysama.work" target="_blank">个人网站主页</a>
                </li>
              </ul>
            </div>
          </div>

          <!--项目:WEB前端-->
          <div class="item">
            <header class="item-hd">WEB前端</header>
            <div class="item-bd">
              <ul>
                <li>
                  <a href="https://gitee.com/chengcurry30/frontend-vue2">AI开放社区</a> 集成了很多AI工具
                </li>
                <li>
                  <a href="https://gitee.com/chengcurry30/study-online">微服务课程售卖系统</a>
                  使用微服务技术打造的在线课程售卖系统
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <!--奖项/证书-->
      <section class="prize">
        <header class="section-hd">
          <span class="section-title-l"></span>
          <h2 class="section-title">奖项/证书</h2>
          <span class="section-title-r"></span>
        </header>
        <div class="section-bd">

          <!--技能:WEB后端开发-->
          <div class="item">
            <header class="item-hd">
              <span class="item-time">获奖经历</span>
            </header>
            <div class="item-bd">
              <ul class="section-content">
                <li>2023年度“楚怡杯“湖南省职业院校技能竞赛(高职高专WEB应用软件开发)三等奖</li>
                <li>2023年(第19届) 湖南省大学生计算机程序设计竞赛应用开发竞赛三等奖</li>
                <li>第十四届蓝桥杯 软件类 省赛Web应用开发职业院校组 二等奖</li>
                <li>第十三届蓝桥杯全国软件和信息技术专业人才大赛湖南赛区c/c++程序设计大学c组三等奖</li>
                <li>2022RoboCom机器人开发者大赛CAIP编程设计赛道湖南省赛"编程技能普及赛（高职组）竞赛项目三等奖</li>
                <li>2023RoboCom机器人开发者大赛CAIP编程设计赛道湖南省赛"编程技能普及赛（高职组）竞赛项目二等奖(晋级国赛)</li>
                <li>2022年第五届大学生计算机技能应用大赛计算机技能应用赛(java科目)初赛专科组二等奖，决赛三等奖</li>
                <li>2023年“厚浦杯”商业网页设计大赛大二组一等奖</li>
                <li>校内创新创业大赛一等奖</li>
                <li>在校评为三好学生、文明学生、优秀学员</li>
                <li>荣获国家励志奖学金</li>
                <li>已优异的成绩多次获得一等奖学金的荣誉</li>
                <li>通过全国计算机一级考试，熟练运用office等常用的办公软件</li>
                <li>普通话已过二级(二乙)</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <!--致谢-->
      <!-- <section class="thanks">
        <header class="section-hd">
          <span class="section-title-l"></span>
          <h2 class="section-title">致谢</h2>
          <span class="section-title-r"></span>
        </header>
        <div class="section-bd">
          <div class="item">
            <h2 style="font-weight: bold;line-height: 24px;margin-top: 10px">
              感谢您在百忙之中阅读这份履历，诚挚的期望能得到面试的机会，希望有荣幸能为公司效力！再次致以我最诚挚的谢意！！</h2>
          </div>
        </div>
      </section> -->

    </div>
  </div>
</template>

<script>
import $ from 'jquery'
export default {
  name: 'Content',
  components: {},
  data() {
    return {
      msg: 'Welcome to Your Vue.js App',
      BoxList: [
        {
          class: false,
          imgUrl: require('../../assets/add-circle.png')
        },
        {
          class: false,
          imgUrl: require('../../assets/add-circle.png')
        },
        {
          class: false,
          imgUrl: require('../../assets/add-circle.png')
        },
        {
          class: false,
          imgUrl: require('../../assets/add-circle.png')
        },
        {
          class: false,
          imgUrl: require('../../assets/add-circle.png')
        },
      ],
      ClassLayout: '',

    }
  },
  watch: {
    '$store.state.changeLayoutClass': {
      handler(newVal) {
        this.ClassLayout = newVal ? 'HLayout' : '';
      }
    }
  },
  methods: {
    showBox(flag) {
      if (this.BoxList[flag].class) {
        this.BoxList[flag].class = false;
        this.BoxList[flag].imgUrl = require('../../assets/add-circle.png')
      } else {
        this.BoxList[flag].class = true;
        this.BoxList[flag].imgUrl = require('../../assets/minus-circle.png')
      }
    }
  },
  mounted() {
    $(document).ready(function () {
      if ($('ol:first').css('list-style-type') == 'none') { /* For IE6/7 only. */
        $('ol ol').each(function (i, ol) {
          ol = $(ol);
          var level1 = ol.closest('li').index() + 1;
          ol.children('li').each(function (i, li) {
            li = $(li);
            var level2 = level1 + '.' + (li.index() + 1);
            li.prepend('<span>' + level2 + '</span>');
          });
        });
      }
    });
  }
}
</script>
<style lang="less" scoped>
.HLayout {
  flex-direction: column !important;

  .content-left {
    border-right: none;
  }

  .section-hd {
    height: 50px;

    .section-title {
      font-size: 25px;
    }
  }


}
</style>
